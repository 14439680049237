<template>
  <v-card tile height="100%">
    <v-card-title :style="`height: 58px; background: ${systemDetails.themecolor}; color: ${systemDetails.textcolor}`">
      {{ $t('workflowDetails') }}
      <v-spacer></v-spacer>
      <v-progress-circular indeterminate size="22" v-if="pdfLoading"></v-progress-circular>
      <v-icon v-else @click="downloadDocument(modelObj)" color="success">mdi-download</v-icon>
      <v-icon class="ml-2" @click="closeWorkflowDetail" color="error">mdi-close-circle-outline</v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pl-2" style="color:black!important; height: calc(100% - 112px); overflow-y: auto;">
      <div class="pa-2 ma-2 mb-0">
        <v-row>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('subject') }}:</span>
            <span class="text-left pb-0 pt-0">{{ modelObj.subject || '' }}</span>
            <v-icon size="20" class="pl-1" @click="navigateToOD(modelObj.document)">mdi-open-in-new</v-icon>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('description') }}:</span>
            <span class="text-left pb-0 pt-0">{{ modelObj.description || '' }}</span>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('createdBy') }}:</span>
            <span class="text-left pb-0 pt-0">{{ getUsername(modelObj.createdby) }}</span>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('createdOn') }}:</span>
            <span class="text-left pb-0 pt-0">{{ modelObj.created_at ? $formatter.formatDate(modelObj.created_at, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`)  : '' }}</span>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('status') }}:</span>
            <span class="text-left pb-0 pt-0">{{ getWorkflowStatusName(modelObj.status)  }}</span>
          </v-col>
          <v-col cols="12">
            <span class="subtitle-1 font-weight-bold">{{ $t('recipients') }}</span>
            <v-simple-table dense class="bordered--table pa-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="width:25%">{{ $t('user')}}</th>
                    <th class="text-left" style="width:5%">{{ $t('order') }}</th>
                    <th class="text-left" style="width:15%">{{ $t('status') }}</th>
                    <th class="text-left" style="width:20%">{{ $t('comments') }}</th>
                    <th class="text-left" style="width:20%">{{ $t('recipientComments') }}</th>
                    <th class="text-left" style="width:10%">{{ $t('reviewedAt') }}</th>
                    <th class="text-left" style="width:12%">{{ $t('reminderDate') }}</th>
                    <th class="text-left" style="width:14%">{{ $t('action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in modelObj.recipients" :key="i">
                    <td>{{ getUsername(item.userid) }}</td>
                    <td>{{ item.order }}</td>
                    <td>{{ getRecipientStatusName(item.status) }}</td>
                    <td>{{ item.comments }}</td>
                    <td>{{ item.recipientcomments }}</td>
                    <td><span class="d-inline-block text-truncate">{{ item.reviewedat ? $formatter.formatDate(item.reviewedat, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`)  : ''  }}</span></td>
                    <td><span class="d-inline-block text-truncate">{{ item.remindersentat ? $formatter.formatDate(item.remindersentat, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`)  : ''  }}</span></td>
                    <td>
                      <v-flex>
                        <v-icon color="teal" @click="openRecipientCommentDialog(item.userid)">{{ commonButtonLoader ? 'mdi-spin mdi-loading' : 'mdi-chat' }}</v-icon>
                        <v-icon color="primary" @click="resetRecipientAction(item.userid, i)" v-if="item.status">{{ commonButtonLoader ? 'mdi-spin mdi-loading' : 'mdi-filter-off' }}</v-icon>
                      </v-flex>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <span class="subtitle-1 font-weight-bold">{{ $t('escalation') }}</span>
            <v-col cols="12">
                <span class="subtitle-1 font-weight-bold">{{ $t('notify') }}</span>
                <v-simple-table dense class="bordered--table pa-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th id="" class="text-left" style="width:25%">{{ $t('userOrGroup')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in modelObj.usersorgroupstonotify" :key="i">
                        <td>{{ getUserOrGroupname(item) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" v-if="modelObj.escalationdeadlinedate">
                <span class="font-weight-bold">{{ $t('date') }} : </span> {{ $formatter.formatDate(modelObj.escalationdeadlinedate, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`) }}
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" v-if="modelObj.escalationdeadlinedays">
                <span class="font-weight-bold"> {{ $t('days') }}: </span> {{ modelObj.escalationdeadlinedays }}
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" v-if="modelObj.notifyonactions && modelObj.notifyonactions.length > 0">
                <span class="font-weight-bold"> {{ $t('notifyOn') }} : </span> {{ getNotifyActions(modelObj.notifyonactions) }}
                </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" v-if="modelObj.escalationaction">
                <span class="font-weight-bold"> {{ $t('action') }}: </span> {{ getEscalationAction(modelObj.escalationaction) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        </div>
      </v-card-text>
  <v-divider></v-divider>
  <v-card-actions style="height: 52px">
    <v-flex class="text-center">
      <v-btn color="error" @click="closeWorkflowDetail">{{ $t('close') }}</v-btn>
    </v-flex>
  </v-card-actions>
  <v-dialog v-model="recipientCommentsDialog" width="1600">
    <v-card class="pa-0">
      <v-card-title class="pa-0">
        <v-toolbar :color="$vuetify.theme.dark ? 'black' : systemDetails.themecolor" :dark="$vuetify.theme.dark" height="55" :style="`color: ${$vuetify.theme.dark ? 'white' : systemDetails.textcolor}`">
          <v-toolbar-title class="body">{{ $t('comments') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="recipientCommentsDialog = false" :color="$vuetify.theme.dark ? 'white' : systemDetails.textcolor">mdi-close-circle-outline</v-icon>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-col cols="auto" class="flex-grow-1 flex-shrink-0 pa-0">
          <v-responsive class="overflow-y-hidden fill-height">
            <v-card flat class="d-flex flex-column fill-height">
              <v-card-text class="overflow-y-auto pa-0 pr-2 pt-3 chat-body">
                <template v-for="(comment, i) in listOfComments">
                  <div :key="i" :class="{ 'd-flex flex-row-reverse': comment.iscreator }">
                    <v-avatar size="25px" :color="comment.userobject[0].profile ? '' : 'indigo'" class="mx-2" v-if="!comment.iscreator">
                      <img v-if="comment.userobject[0].profile" alt="Avatar" :src="$formatter.getAppImageURL() +'/' + comment.userobject[0].profile">
                      <span v-else class="caption white--text">
                        {{  comment.userobject[0].name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() }}
                      </span>
                    </v-avatar>
                    <v-chip :color="comment.iscreator ? 'primary' : ''" dark style="height:auto;white-space: normal;" class="pa-1 pl-2 mb-1 caption" :ripple="false">
                      {{ comment.message }}
                    </v-chip>
                  </div>
                  <p class="ml-2 caption font-weight-medium" :key="`date_${i}`" :class="{ 'd-flex flex-row-reverse': comment.iscreator }">
                    <!-- {{ $formatter.fromUtcToLocal(comment.sentdate, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') }} -->
                    {{ $formatter.formatDate(comment.sentdate, '', `${userDetails.dateformat} HH:mm`) }}
                  </p>
                </template>
              </v-card-text>
                <v-card-text class="">
                  <v-textarea v-model="replyObj.message" :label="$t('typeHere')" type="text" rows="3" no-details outlined :append-outer-icon="commonButtonLoader ? 'mdi-spin mdi-loading' : 'mdi-send'"
                    @click:append-outer="saveComment()" hide-details/>
                </v-card-text>
              </v-card>
            </v-responsive>
          </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['workflowDetail', 'documentLink', 'recordName'],
  data () {
    return {
      modelObj: {},
      pdfLoading: false,
      recipientCommentsDialog: false,
      listOfComments: [],
      currentReceipientId: null,
      commonButtonLoader: false,
      replyObj: {
        message: '',
        iscreator: true
      }
    }
  },
  mounted () {
    this.modelObj = this.$formatter.cloneVariable(this.workflowDetail)
  },
  computed: {
    ...mapGetters(['getUsers', 'systemDetails', 'getUserGroups', 'userDetails'])
  },
  methods: {
    getRecipientStatusName (status) {
      const resultFound = this.listOfWorkflowStatus.find(x => x.id === status)
      if (resultFound) return resultFound.name
      else return ''
    },
    getUserOrGroupname (item) {
      if (item.userid) return this.getUsername(item.userid)
      else return this.getGroupName(item.groupid)
    },
    getNotifyActions (actions) {
      const result = this.listOfNotifyActions.filter(x => actions.includes(x.id))
      if (result && result.length > 0) return result.map(x => x.name).join(',')
      else return ''
    },
    getEscalationAction (action) {
      const result = this.listOfEscalationItems.find(x => x.id === action)
      if (result) return result.text
      else return ''
    },
    closeWorkflowDetail () {
      this.$eventBus.$emit('closeWorkflowDetail')
    },
    downloadDocument (document) {
      this.pdfLoading = true
      const getBase64Content = this.pdfContentGeneration(document)
      this.$api.execute('post', 'files/generate_pdf', { content: getBase64Content })
        .then((response) => {
          const fileName = response.data
          window.open(process.env.VUE_APP_URL + 'files/download/?fileName=' + fileName, '_blank')
        }).finally(() => {
          this.pdfLoading = false
        })
    },
    pdfContentGeneration (document) {
      let escalation = ''
      if (document.escalationdeadlinedate) escalation += `<h4 style="margin-top: 0px;">${this.$t('date')}</h4>&nbsp;&nbsp;<span>${this.$formatter.formatDate(document.escalationdeadlinedate, 'DD.MM.YYYYTHH:mm:ss', `${this.userDetails.dateformat} HH:mm`)}</span><br>`
      if (document.escalationdeadlinedays) escalation += `<h4 style="margin-top: 0px;">${this.$t('days')}</h4>&nbsp;&nbsp;<span>${document.escalationdeadlinedays}</span><br>`
      if (document.notifyonactions && document.notifyonactions.length > 0) escalation += `<h4 style="margin-top: 0px;">${this.$t('notificationOn')}</h4>&nbsp;&nbsp;<span>${this.getNotifyActions(document.notifyonactions)}</span><br>`
      if (document.escalationaction) escalation += `<h4 style="margin-top: 0px;">${this.$t('action')}</h4>&nbsp;&nbsp;<span>${this.getEscalationAction(document.escalationaction)}</span><br>`
      let tableHeaders = ''
      const headers = ['user', 'order', 'status', 'comments', 'recipientComments', 'reviewedAt', 'reminderDate']
      for (let i = 0; i < headers.length; i++) {
        tableHeaders += `<th>${this.$t(headers[i])}</th>`
      }
      let tableBody = ''
      for (let i = 0; i < document.recipients.length; i++) {
        tableBody += `<tr>
        <td>${this.getUsername(document.recipients[i].userid)}</td>
        <td>${document.recipients[i].order}</td>
        <td>${this.getRecipientStatusName(document.recipients[i].status)}</td>
        <td>${document.recipients[i].comments || ''}</td>
        <td>${document.recipients[i].recipientcomments || ''}</td>
        <td>${document.recipients[i].reviewedat ? this.$formatter.formatDate(document.recipients[i].reviewedat, 'DD.MM.YYYYTHH:mm:ss', `${this.userDetails.dateformat} HH:mm`) : ''}</td>
        </tr>`
      }
      // live conflict content at line 236
      // <td>${document.recipients[i].reviewedat ? this.$formatter.formatDate(document.recipients[i].reviewedat, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') : ''}</td>
      // <td>${document.recipients[i].remindersentat ? this.$formatter.formatDate(document.recipients[i].remindersentat, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') : ''}</td>
      const content = `
      <html>
        <head>
          <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
          <style>
              body {
                  font-family: sans-serif;
                  font-size: 13px;
              }
              h4, h3 {
                  font-size: 13px;
                  font-weight: bold;
                  margin: 15px 0px;
                  display: inline-block;
              }
              table {
                  width: 100%;
                  border-collapse: collapse;
                  border: 1px solid #ddd;
              }
              th, td {
                  text-align: start;
                  min-width: 80px;
                  padding: 8px 10px;
                  border-right: 1px solid #ddd;
                  border-bottom: 1px solid #ddd;
              }
              th {
                  text-transform: uppercase;
                  font-size: 11px;
                  font-weight: 600;
                  color: rgba(0, 0, 0, 0.55);
              }
          </style>
        </head>
        <body style="padding: 10px 50px;">
          <div style="padding: 10px 30px; margin-top: 30px; border: 1px solid #ddd;" class="container-fluid">
            <div class="row">
              <div class="col-xs-8"><h4>${this.recordName}</h4></div>
              <div class="col-xs-4">
                <div class="row"><div class="col-xs-12" style="text-align: end;"><h4 class="mb-0">${this.$t('createdBy')} :</h4>&nbsp;&nbsp;<span>${this.getUsername(document.createdby)}</span></div></div>
                <div class="row"><div class="col-xs-12" style="text-align: end;"><h4 class="mb-0">${this.$t('createdOn')} :</h4>&nbsp;&nbsp;<span>${this.$formatter.formatDate(document.created_at, 'DD.MM.YYYYTHH:mm:ss', `${this.userDetails.dateformat} HH:mm`)}</span></div></div>
                <div class="row"><div class="col-xs-12" style="text-align: end;"><h4>${this.$t('status')} :</h4>&nbsp;&nbsp;<span>${this.getWorkflowStatusName(document.status)}</span></div></div>
              </div>
            </div>
          </div>
          <div style="padding: 10px 30px; margin-top: 15px; border: 1px solid #ddd;">
            <h4>${this.$t('name')} :</h4>&nbsp;&nbsp;<span>${document.document.name ? document.document.name : ''}</span><br>
            <h4>${this.$t('subject')} :</h4>&nbsp;&nbsp;<span>${document.subject || ''}</span><br>
            <h4>${this.$t('description')} :</h4>&nbsp;&nbsp;<span>${document.description || ''}</span><br>
            <h4>${this.$t('recipients')} </h4><br>
            <table>
                <tr>
                    ${tableHeaders}
                </tr>
                ${tableBody}
            </table>
            <h3 style="margin-bottom: 10px; margin-top: 30px;">${this.$t('escalation')}</h3><br>
            ${escalation}
          </div>
        </body>
      </html>`
      return btoa(unescape(encodeURIComponent(content)))
    },
    openRecipientCommentDialog (userid) {
      this.commonButtonLoader = true
      this.$api.execute('post', 'documentworkflowconversation/get_workflow_conversation', { workflowid: this.workflowDetail._id, userid }).then(response => {
        this.currentReceipientId = userid
        this.listOfComments = response.data
        this.recipientCommentsDialog = true
      }).finally(() => {
        this.commonButtonLoader = false
      })
    },
    saveComment () {
      if (this.replyObj.message) {
        const model = this.$formatter.cloneVariable(this.replyObj)
        model.workflowid = this.workflowDetail._id
        model.userid = this.currentReceipientId
        model.iscreator = true
        this.commonButtonLoader = true
        this.$api.execute('post', 'documentworkflowconversation/add_comment', model).then(response => {
          model.sentdate = this.$formatter.getCurrentDateTimeUTC()
          this.listOfComments.push(model)
          this.replyObj = {
            message: '',
            iscreator: true
          }
        }).finally(() => {
          this.commonButtonLoader = false
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'enterMessage' })
    },
    resetRecipientAction (userid, index) {
      this.commonButtonLoader = true
      this.$api.execute('post', 'documentworkflowconversation/reset_workflow', { workflowid: this.workflowDetail._id, userid, iscreator: true }).then(response => {
        this.modelObj.recipients[index].status = ''
        this.modelObj.recipients[index].reviewedat = null
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'saveSuccess' })
      }).finally(() => {
        this.commonButtonLoader = false
      })
    }
  }
}
</script>
